import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Beersection() {
  return (
    <section className="mt-20 mx-4 md:mx-12 mb-20">
      <h2 className="text-center uppercase font-bold text-2xl xl:text-4xl pb-14 px-5 xl:px-[30%] text-[#490E13]">
        La birra è il nostro mondo, la nostra casa, la nostra passione
      </h2>
      <div className="flex justify-center pb-16">
        <StaticImage
          src="../../../../images/birra.jpg"
          loading="eager"
          width={600}
          formats={["auto", "webp", "avif"]}
          transformOptions={{ fit: "cover", cropFocus: "attention" }}
          quality={90}
          className="w-[30%]"
          alt="birra"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 place-content-center">
        <div>
          <StaticImage
            src="../../../../images/patatine.jpeg"
            loading="eager"
            width={600}
            formats={["auto", "webp", "avif"]}
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
            quality={90}
            alt="birra"
          />
        </div>
        <div>
          <StaticImage
            src="../../../../images/burger_2.jpeg"
            loading="eager"
            width={600}
            formats={["auto", "webp", "avif"]}
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
            quality={90}
            alt="birra"
          />
        </div>
        <div>
          <StaticImage
            src="../../../../images/burger_1.jpeg"
            loading="eager"
            width={600}
            formats={["auto", "webp", "avif"]}
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
            quality={90}
            className=""
            alt="birra"
          />
        </div>

        <div>
          <StaticImage
            src="../../../../images/pizza.jpeg"
            loading="eager"
            width={600}
            formats={["auto", "webp", "avif"]}
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
            quality={90}
            alt="birra"
          />
        </div>
      </div>
    </section>
  )
}

import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import * as styles from "../components/index.module.css"
import "../styles/global.css"

import Hero from "../components/pages/home-page/hero/hero"
import About from "../components/pages/home-page/about-section/about"
import Menu from "../components/pages/home-page/menu/menu"
import Beersection from "../components/pages/home-page/beer-section/beer-section"
// import VideoSection from "../components/pages/home-page/video-section/video-section"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <About />
    <Menu />
    <Beersection />
    {/* <VideoSection /> */}
  </Layout>
)

export default IndexPage

import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../../../ui/button/button"

const Hero = () => {
  return (
    <section className="h-screen relative">
      <div className="absolute w-full h-screen bg-[#0000005e]" />
      <StaticImage
        src="../../../../images/burgers.jpg"
        loading="eager"
        width={600}
        formats={["auto", "webp", "avif"]}
        transformOptions={{ fit: "cover", cropFocus: "attention" }}
        quality={90}
        className="absolute w-full h-full top-0 left-0 -z-20"
        alt="Logo"
        // style={{ marginBottom: `var(--space-3)` }}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-4 md:px-12">
        <h1 className="z-50 text-white">
          <span className="md:text-left font-bold text-4xl md:text-5xl lg:text-6xl">
            THE BREW HOUSE
          </span>
          <br />
          <span className="text-2xl lg:text-4xl">
            birreria, pub, cucina, hamburgeria, pinseria, pub caffè
          </span>
        </h1>
        <Button
          link={"https://goo.gl/maps/Ub93QPusZChkMojx9"}
          title={"vieni a trovarci"}
        />
      </div>
      <ul className="absolute bottom-10 left-0 flex-cols sm:flex text-white uppercase px-0 md:px-8">
        <li>
          <Button
            link={"https://instagram.com/thebrewhouseintrobio"}
            title={"instagram"}
          />
        </li>
        <li className="pl-0 sm:pl-8 pt-10 sm:pt-0">
          <Button
            link={"https://m.facebook.com/thebrewhouseintrobio/"}
            title={"facebook"}
          />
        </li>
      </ul>
    </section>
  )
}

export default Hero

import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Menu() {
  return (
    <section className="mt-20 mx-4 md:mx-12 relative h-[400px]">
      <StaticImage
        src="../../../../images/burgers.jpg"
        loading="eager"
        placeholder="blurred"
        width={600}
        formats={["auto", "webp", "avif"]}
        transformOptions={{ fit: "cover", cropFocus: "attention" }}
        quality={90}
        className="absolute w-full h-[400px] top-0 left-0 -z-20"
        alt="menu"
        // style={{ marginBottom: `var(--space-3)` }}
      />
      <div className="w-full  flex items-center justify-center absolute top-0 left-0 bottom-0">
        <a href="https://thebrewhouse.arweb-app.it">
          <span className="text-white bg-[#490E13] border-8 border-white rounded-full p-12">
            SCOPRI IL NOSTRO MENU
          </span>
        </a>
        {/* <span className="text-center inline-block w-[200px] h-[200px] bg-[#490E13] border-8 border-white rounded-full text-white">
          <span className="text-white">SCOPRI IL NOSTRO MENù</span>
        </span> */}
      </div>
    </section>
  )
}

import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function About() {
  return (
    <section className="mx-4 md:mx-12 mt-20 bg-[#490E13] p-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="pb-[57px] md:text-left text-center font-bold text-5xl lg:text-7xl text-[#DDB2A5]">
            CHI SIAMO
          </h2>
          <p className="pr-0 md:pr-[70px] text-[#DDB2A5] text-center md:text-left text-md md:text-lg tracking-wide">
            The Brew House, un bar di paese in chiave moderna, luogo di incontro
            dalle prime ore del mattino fino a notte fonda. Quindi: caffetteria,
            birrificio e cucina si fondono per creare il locale che non c’era. E
            il nome, solo un altro inglesismo? No! Brew è il verbo inglese
            utilizzato sia per la birrificazione che per la preparazione del
            caffè. Protagonisti indiscussi della Brew House.
          </p>
        </div>
        <div>
          <StaticImage
            src="../../../../images/about.jpg"
            loading="eager"
            width={600}
            formats={["auto", "webp", "avif"]}
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
            quality={90}
            className="w-full h-full"
            alt="Logo"
            // style={{ marginBottom: `var(--space-3)` }}
          />
        </div>
      </div>
    </section>
  )
}
